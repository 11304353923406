import React, { useEffect, useState, useTranslation } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Typography, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import rows from "./data/TableData";
import ExpirationUpdate from "./ExpirationUpdate/ExpirationUpdate";
import "./ExpirationPage.scss";
import Swal from "sweetalert2";
import { exGetRentData } from "../../services/expiration.service";

const ExpirationPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedShop, setSelectedShop] = useState(null);
  const [openshop, setOpenShop] = useState(false);
  const [openshopE, setOpenShopE] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalSelectedDeposit, setTotalSelectedDeposit] = useState(0);
  const [totalSelectedRent, setTotalSelectedRent] = useState(0);
  const [totalSelectedOverdue, srtTotalSelectedOverdue] = useState(0);
  const [totalSelectedBlockNumber, setTotalSelectedBlockNumber] = useState(0);
  const [nameRent, setTotalSelectednameRent] = useState(null);
  const [nameShop, setTotalSelectedNameShop] = useState(null);
  const [idShop, setIdShop] = useState(null);

  const [rentData, setRentData] = useState([]);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [refreshData, setRefreshData] = useState(0);

  console.log("rentData", rentData);

  useEffect(() => {
    exGetRentData().then((res) => {
      if (res.status === 200) {
        const updatedData = res.data.rent.map((row) => ({
          ...row,
          total: row.TentDeposit + row.Rent + row.ElectricityBill,
        }));
        setRentData(updatedData);
      }
    });
  }, [refreshData]);

  const getUniqueNames = (items, key) => {
    return [...new Set(items.map((item) => item[key]))];
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      rentData.find((row) => row.id === id)
    );

    const totalSelectedDeposit = selectedRowsData.reduce((accumulator, row) => {
      return accumulator + row.Deposit;
    }, 0);

    const blockAndRent = selectedRowsData.map((row) => ({
      blockNumber: row.BlockNumber,
      rent: row.Rent,
      // overdue: row.ExpOverdue === null ? row.ReceiptOverdue : row.ExpOverdue,
      overdue: row.ReceiptOverdue,
      name: row.tenant.name,
      nameShop: row.NameShop,
      endrent: row.Endrent,
      id: row.id,
      billId: row.BillId,
    }));

    // const name = blockAndRent.map((item) => item.name);
    // const nameShop = blockAndRent.map((item) => item.nameShop);
    const blockNumbers = blockAndRent.map((item) => item.blockNumber);
    const rents = blockAndRent.map((item) => item.rent);
    const overdues = blockAndRent.map((item) => item.overdue);
    const uniqueNames = getUniqueNames(blockAndRent, "name");
    const uniqueNameShops = getUniqueNames(blockAndRent, "nameShop");
    const uniqueIdShop = getUniqueNames(blockAndRent, "id");

    setIdShop(blockAndRent);
    setTotalSelectednameRent(uniqueNames);
    setTotalSelectedNameShop(uniqueNameShops);
    setTotalSelectedBlockNumber(blockNumbers);
    setTotalSelectedRent(rents);
    srtTotalSelectedOverdue(overdues);
    setSelectedRows(blockAndRent);
    setTotalSelectedDeposit(totalSelectedDeposit);
  };

  const handleOpen = (imageUrl) => {
    console.log(imageUrl);

    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterDuplicatesInModal = (data) => {
    const uniqueNames = new Set();
    return data.filter((item) => {
      if (uniqueNames.has(item.tenant.name)) {
        return false;
      }
      uniqueNames.add(item.tenant.name);
      return true;
    });
  };

  const handleResultClick = (selectedNameShop) => {
    const shop = rentData.find((row) => row.tenantName === selectedNameShop);
    setSelectedShop(shop);
    setSearchQuery(selectedNameShop);
    setOpenShop(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleOpenShopE = () => {
    // ตรวจสอบว่ามีการเลือกหรือไม่
    if (selectedRows.length > 0) {
      setOpenShopE(true);
    } else {
      // ถ้าไม่มีการเลือก แสดง SweetAlert2
      Swal.fire({
        icon: "error",
        title: "โปรดเลือกข้อมูลที่ท่านต้องการก่อน",
        // text: 'โปรดเลือกข้อมูลที่',
      });
    }
  };

  const handleCloseShopE = () => {
    setOpenShopE(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "RentPayDate",
      headerName: "วันที่เก็บค่าเช่า",
      width: 120,
      renderCell: (params) => {
        const RentDateValue = params.row.Endrent;
        const ExpDateValue = params.row.ExpEndDate;
        if (ExpDateValue === null) {
          return <div style={{ fontWeight: 500 }}>{RentDateValue}</div>;
        } else {
          return <div style={{ fontWeight: 500 }}>{ExpDateValue}</div>;
        }
      },
    },
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 100,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "ImgShop",
      headerName: "ภาพหน้าร้าน",
      width: 120,
      renderCell: (params) => (
        <img
          src={uploadPath + params.value}
          alt="Shop"
          style={{
            width: 120,
            borderRadius: "0.375rem",
            width: "59px",
            height: "59px",
          }}
          onClick={() => handleOpen(params.value)}
        />
      ),
    },
    {
      field: "Name",
      headerName: "ชื่อผู้เช่า",
      sortable: false,
      width: 140,
      renderCell: (params) => (
        <div style={{ paddingLeft: "0" }}>
          <p>{params.row.tenant.name}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 130,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 120,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 90,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "NotYetPaid",
      headerName: "คงค้าง",
      width: 100,
      renderCell: (params) => {
        const startRemainValue = params.row.ReceiptOverdue;
        const ExpRemainValue = params.row.ExpOverdue;
        if (ExpRemainValue === null) {
          return new Intl.NumberFormat("th-TH", {
            maximumFractionDigits: 0,
          }).format(startRemainValue);
        } else {
          return new Intl.NumberFormat("th-TH", {
            maximumFractionDigits: 0,
          }).format(ExpRemainValue);
        }
      },
    },
    {
      field: "fines",
      headerName: "ค่าปรับ",
      width: 100,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "----฿";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "total",
      width: 140,
      // valueFormatter: (params) => {
      //   if (params.value == null) {
      //     return '';
      //   }
      //   return new Intl.NumberFormat('th-TH', {
      //     maximumFractionDigits: 0,
      //   }).format(params.value);
      // },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "30px" }}
          >
            ยอดรวมที่ต้องเก็บ
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        let Rent = params.row.Rent;
        let Own =
          params.row.ExpOverdue === null
            ? params.row.ReceiptOverdue
            : params.row.ExpOverdue;
        let Fine = params.row.fines;

        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(Rent + Own + Fine);

        // if (!params.value) {
        //   return <p>----฿</p>;
        // }
        return `${formattedValue}`;
      },
    },
    {
      field: "NumberRentalDays",
      width: 130,
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "30px" }}
          >
            จำนวนวันรอบบิล
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;

        if (!PayRentvalue) {
          return <p>--/--/--</p>;
        }
        return PayRentvalue;
      },
    },
    {
      field: "Status",
      headerName: "สถานะการจ่าย",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.status_pay == null) {
          return <p>--/--/--</p>;
        }
        return (
          <div
            style={{
              color: params.row.status_pay == 1 ? "#00D715" : "#FF2C2C",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {params.row.status_pay == 1 ? "จ่ายแล้ว" : "ยังไม่จ่าย"}
            <p
              style={{
                fontSize: "12px",
                lineHeight: "12.5px",
                color: "#9993B4",
                textAlign: "center",
              }}
            >
              {params.row.ExpEndDate}
            </p>
          </div>
        );
      },
    },
    {
      field: "slip",
      headerName: "หลักฐานการจ่าย",
      width: 130,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleOpen(params.value)}
        >
          <img
            src={
              params.value ? uploadPath + params.value : "/images/no-image.png"
            }
            alt={""}
            style={{
              maxHeight: "90px", // ปรับความสูงตามต้องการ
              maxWidth: "100%", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          {/* <p style={{ color: '#3F8CFF' }}>view</p> */}
        </div>
      ),
    },
  ];

  const rowsFiltered = rentData.filter((row) => {
    if (
      (age === "" || row.zone_name === age) && // Filter by Zone
      row.tenant.name.includes(searchQuery)
    ) {
      return true;
    } else {
      return false;
    }
  });

  const zones = Array.from(new Set(rentData.map((row) => row.zone_name)));

  const zoneOptions = zones
    .sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((zone, index) => (
      <MenuItem key={index} value={zone}>
        {zone}
      </MenuItem>
    ));

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.totalDeposit += row.fines;
      accumulator.totalRent += row.Rent;
      accumulator.totalElectricityBill += row.ExpOverdue;
      return accumulator;
    },
    {
      totalDeposit: 0,
      totalRent: 0,
      totalElectricityBill: 0,
    }
  );

  const { totalDeposit, totalRent, totalElectricityBill } = totals;

  const formattedTotalDeposit = totalDeposit.toLocaleString();
  const formattedTotalRent = totalRent.toLocaleString();
  const formattedTotalElectricityBill = totalElectricityBill.toLocaleString();

  const totalAll = totalDeposit + totalRent + totalElectricityBill;
  const formattedTotalAll = totalAll.toLocaleString();

  const dataTotal = [
    { p: "รวมค่าเช่า : ", price: formattedTotalRent },
    { p: "รวมคงค้าง : ", price: formattedTotalElectricityBill },
    { p: "รวมค่าปรับ : ", price: formattedTotalDeposit },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          วันที่ จ่าย/เก็บ ค่าเช่า
        </div>
        <div className="searchE">
          <div className="">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="โซน"
                value={age}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <Button
            className="button-h"
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            onClick={handleOpenShop}
          >
            ค้นหาผู้เช่า
          </Button>
          {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
          <Modal open={openshop} onClose={handleCloseShop}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                p: 4,
              }}
            >
              <TextField
                label="ค้นหาร้าน"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchInputChange}
                autoFocus
                sx={{ mb: 2 }}
                InputProps={{
                  style: {
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
              <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#6C5DD3",
                        }}
                      >
                        เลือกผู้เช่า
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterDuplicatesInModal(rowsFiltered).map((result) => (
                      <tr
                        key={result.id}
                        onClick={() => handleResultClick(result.tenant.name)}
                        style={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#f0f0f0" },
                        }}
                      >
                        <td
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {result.tenant.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Modal>

          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            onClick={handleOpenShopE}
            className="button-h"
          >
            อัปเดตข้อมูล
          </Button>
        </div>
      </div>

      <Modal open={openshopE} onClose={handleCloseShopE}>
        <Box
          className="width-modalE"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <ExpirationUpdate
            onClose={handleCloseShopE}
            totalSelectedDeposit={totalSelectedDeposit}
            rents={totalSelectedRent}
            overdus={totalSelectedOverdue}
            idShop={idShop}
            blockAndRent={selectedRows}
            blockNumbers={totalSelectedBlockNumber}
            nameRent={nameRent}
            nameShop={nameShop}
            setRefreshData={setRefreshData}
          />
        </Box>
      </Modal>

      <div className="data-box">{totalData}</div>

      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        // pagination
        // pageSize={10}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={uploadPath + selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExpirationPage;
