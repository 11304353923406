import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { Suspense, useState } from "react";
import "./App.scss";

/* Component pages */
import ConfigPage from "./pages/config/config";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/login";
import RegisterPage from "./pages/register/register";
import { useSelector } from "react-redux";
import GuestRoutes from "./utils/guestRoutes";
import LangConfigPage from "./pages/langconfig";
import AdminPage from "./pages/admin/admin";
import BounceLoading from "./components/ui/loading/bounce/bounce";
import SpawnLoading from "./components/ui/loading/spawn/spawn";
import WebInfoPage from "./pages/webinfo/webinfo";
import ProfileAdminPage from "./pages/admin/profile/profile";
import ForgetPasswordPage from "./pages/forgetpassword/forgetpassword";
import CategoryPage from "./pages/category/category";
import PostPage from "./pages/post/post";
import InboxPage from "./pages/inbox/inbox";
import ResetPasswordPage from "./pages/resetpassword/resetpassword";
import ProductsPage from "./pages/products/ProductsPage";
import ExpirationPage from "./pages/expiration/ExpirationPage";
import ProductCategory from "./pages/productCategory/ProductCategory";
import Amount from "./pages/amount/Amount";
import Vat from "./pages/vat/Vat";
import { createContext } from "react";
import DefectivePage from "./pages/defective/DefectivePage";
import StockPage from "./pages/stock/StockPage";
import ExportDetailsPage from "./pages/exportDetails/ExportDetailsPage";
import ExportDetailsStore from "./pages/exportDetailsStore/ExportDetailsStore.jsx";
import ProductsUpdate from "./pages/products/components/ProductsUpdate/ProductsUpdate.jsx";
import RentalAmount from "./pages/rentalAmount/RentalAmount.jsx";
import RentalAmountDetails from "./pages/rentalAmountDetails/RentalAmountDetails.jsx";
import BlockSize from "./pages/blockSize/BlockSize.jsx";
import ExpirationUpdate from "./pages/expiration/ExpirationUpdate/ExpirationUpdate.jsx";
import FormAddBlock from "./pages/blockSize/components/FormAddBlock.jsx";
import BusinessType from "./pages/businessType/BusinessType.jsx";
import FormAddColor from "./pages/businessType/components/FormAddColor.jsx";
import ProductsAddPage from "./components/product/add/ProductsaddPage.jsx";
import PayRent from "./pages/payRent/PayRent.jsx";
import PayRenttDetails from "./pages/payRent/detailPayRent/PayRenttDetails.jsx";
import ProofPayment from "./pages/proofPayment/ProofPayment.jsx";
import ProofPaymentDetail from "./pages/proofPaymentDetail/ProofPaymentDetail.jsx";
import StoreRent from "./pages/storeRent/StoreRent.jsx";
import StoreRentupdate from "./pages/storeRent/StoreRentupdate.jsx";
import BillingCyclePage from "./pages/billingcycle/BillingCyclePage.jsx";
import BillingCycleUpdate from "./pages/billingcycle/BillingCycleUpdate/BillingCycleUpdate.jsx";
import HistoryDetails from "./pages/historyElectricWater/detailHistoryElectricWater/HistoryDetails.jsx";
import HistoryElectricWater from "./pages/historyElectricWater/HistoryElectricWater.jsx";
import InvoiceForm from "./components/product/add/Frombill/InvoiceForm.jsx";
import Allstores from "./pages/allstores/AllStoresPage.jsx";
import RentalIncome from "./pages/rentalIncome/RentalIncomePage.jsx";
import ElectricityIncome from "./pages/electricityIncome/ElectricityIncomePage.jsx";
import Waterincome from "./pages/waterincome/WaterincomePage.jsx";
import VariousExpenses from "./pages/variousexpenses/VariousExpensesPage.jsx";
import Graphs from "./pages/dashboard/Graphs.jsx";
import BathroomService from "./pages/bathroomservice/BathroomService.jsx";
import Salary from "./pages/salary/Salary.jsx";
import MaintenanceCcosts from "./pages/maintenanceccosts/MaintenanceCcosts.jsx";
import GarbageCollec from "./pages/garbagecollec/GarbageCollec.jsx";
import WaterBill from "./pages/waterbill/WaterBill.jsx";
import ElectricityBill from "./pages/electricitybill/ElectricityBill.jsx";
import Clean from "./pages/clean/Clean.jsx";
import Miscellaneous from "./pages/miscellaneous/Miscellaneous.jsx";
import ReportPage from "./pages/report/ReportPage.jsx";
import WaterDispenser from "./pages/waterDispenser/WaterDispenser.jsx";
import IceMaker from "./pages/iceMaker/IceMaker.jsx";
import SalaryDetails from "./pages/salary/SalaryDetails.jsx";
import TotalGraphs from "./pages/dashboard/TotalGraphs.jsx";


export const ProductContext = createContext();

function App() {
  const pagesAllow = useSelector((state) => state.app.pages);
  const isDevMode = useSelector((state) => state.app.isDevMode);
  const [productIds, setProductIds] = useState([]);

  return (
    <Suspense>
      {/* Animetion loading */}
      {/* <BounceLoading /> */}
      <SpawnLoading />
      <ProductContext.Provider value={{ productIds, setProductIds }}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            {<Route path="/" element={<Navigate to="/dashboard" />} />}
            {pagesAllow.dashboard && (
              <Route path="/dashboard" element={<DashboardPage />} />
            )}
            {pagesAllow.products && (
              <Route path="/products" element={<ProductsPage />} />
            )}
            {pagesAllow.products && (
              <Route path="/products/add" element={<ProductsAddPage />} />
            )}
            {pagesAllow.products && (
              <Route path="/products/update" element={<ProductsUpdate />} />
            )}
            {pagesAllow.products && (
              <Route path="/expiration" element={<ExpirationPage />} />
            )}
            {pagesAllow.products && (
              <Route path="/billingcycle" element={<BillingCyclePage />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/billingcycle/update"
                element={<BillingCycleUpdate />}
              />
            )}
            {pagesAllow.products && (
              <Route path="/expiration/update" element={<ExpirationUpdate />} />
            )}
            {pagesAllow.products && (
              <Route path="/payRent" element={<PayRent />} />
            )}
            {pagesAllow.products && (
              <Route path="/payrent/details/:id" element={<PayRenttDetails />} />
            )}
            {pagesAllow.products && (
              <Route path="/proof-payment" element={<ProofPayment />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/proof-payment/details/:id"
                element={<ProofPaymentDetail />}
              />
            )}
            {pagesAllow.products && (
              <Route path="/store" element={<StoreRent />} />
            )}
            {pagesAllow.products && (
              <Route path="/store/update" element={<StoreRentupdate />} />
            )}
            {pagesAllow.products && (
              <Route path="/stock" element={<StockPage />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/exportdetails/:id"
                element={<ExportDetailsPage />}
              />
            )}
            {pagesAllow.products && (
              <Route path="/defective" element={<DefectivePage />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/exportdetails/store/:id"
                element={<ExportDetailsStore />}
              />
            )}

            {pagesAllow.products && (
              <Route path="/rentalAmount" element={<RentalAmount />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/rental/detail/:id"
                element={<RentalAmountDetails />}
              />
            )}

            {pagesAllow.products && (
              <Route path="/blockSize" element={<BlockSize />} />
            )}
            {pagesAllow.products && (
              <Route path="/add-block" element={<FormAddBlock />} />
            )}
            {pagesAllow.products && (
              <Route path="/BusinessType" element={<BusinessType />} />
            )}
            {pagesAllow.products && (
              <Route path="/add-color" element={<FormAddColor />} />
            )}
            {pagesAllow.products && (
              <Route path="/history/details/:id" element={<HistoryDetails />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/history/electricWater"
                element={<HistoryElectricWater />}
              />
            )}
            {pagesAllow.products && (
              <Route
                path="/products/add/InvoiceForm"
                element={<InvoiceForm />}
              />
            )}
            {pagesAllow.products && (
              <Route path="/allstores" element={<Allstores />} />
            )}
            {pagesAllow.products && (
              <Route path="/rentalincome" element={<RentalIncome />} />
            )}
            {pagesAllow.products && (
              <Route
                path="/electricityincome"
                element={<ElectricityIncome />}
              />
            )}
            {pagesAllow.products && (
              <Route path="/waterincome" element={<Waterincome />} />
            )}
            {pagesAllow.products && (
              <Route path="/variousexpenses" element={<VariousExpenses />} />
            )}
            {pagesAllow.products && (
              <Route path="/graphs" element={<Graphs />} />
            )}
            {pagesAllow.products && (
              <Route path="/graphs/total" element={<TotalGraphs />} />
            )}
            {pagesAllow.products && (
              <Route path="/bathroomservice" element={<BathroomService />} />
            )}
            {pagesAllow.products && (
              <Route path="/salary" element={<Salary />} />
            )}
            {pagesAllow.products && (
              <Route path="/salary/detail/:id" element={<SalaryDetails />} />
            )}
            {pagesAllow.products && (
              <Route path="/maintenancecosts" element={<MaintenanceCcosts />} />
            )}
            {pagesAllow.products && (
              <Route path="/garbagecollec" element={<GarbageCollec />} />
            )}
            {pagesAllow.products && (
              <Route path="/waterbill" element={<WaterBill />} />
            )}
            {pagesAllow.products && (
              <Route path="/electricitybill" element={<ElectricityBill />} />
            )}
            {pagesAllow.products && <Route path="/clean" element={<Clean />} />}
            {pagesAllow.products && (
              <Route path="/miscellaneous" element={<Miscellaneous />} />
            )}

            {pagesAllow.products && (
              <Route path="/waterDispenser" element={<WaterDispenser />} />
            )}
            {pagesAllow.products && (
              <Route path="/iceMaker" element={<IceMaker />} />
            )}
            {pagesAllow.products && (
              <Route path="/report" element={<ReportPage />} />
            )}

            {pagesAllow.inbox && <Route path="inbox" element={<InboxPage />} />}
            {pagesAllow.category && (
              <Route path="category" element={<CategoryPage />} />
            )}
            {pagesAllow.posts && <Route path="posts" element={<PostPage />} />}
            {pagesAllow.webinfo && (
              <Route path="webinfo" element={<WebInfoPage />} />
            )}
            {pagesAllow.languages && (
              <Route path="languages" element={<LangConfigPage />} />
            )}
            {pagesAllow.configs && (
              <Route path="configs" element={<ConfigPage />} />
            )}
            {pagesAllow.profile && (
              <Route path="profile" element={<ProfileAdminPage />} />
            )}
            {/* Pages setting group */}
            {pagesAllow.productcate && (
              <Route path="productcate" element={<ProductCategory />} />
            )}
            {pagesAllow.amount && <Route path="amount" element={<Amount />} />}
            {pagesAllow.vat && <Route path="vat" element={<Vat />} />}
            {pagesAllow.admins && (
              <Route path="admins" element={<AdminPage />} />
            )}

            {/* {isDevMode && <Route path="componentui" element={<ShowUIComponentPage />} /> } */}
          </Route>
          <Route element={<GuestRoutes />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="forgetpassword" element={<ForgetPasswordPage />} />
            <Route
              path="resetpassword/:token"
              element={<ResetPasswordPage />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ProductContext.Provider>
    </Suspense>
  );
}
export default App;
