import axios from "axios"

export const svIceMakerData = () => {
    return axios.get(`ice_maker/data`).then((res) => res).catch((error) => error);
}

export const svIceMakerCreate = (formData) => {
    return axios.post(`ice_maker/create`, formData).then((res) => res).catch((error) => error);
}

export const svIceMakerUpdate = (formData) => {
    return axios.post(`ice_maker/update`, formData).then((res) => res).catch((error) => error);
}

export const svIceMakerDelete = (id) => {
    return axios.delete(`ice_maker/destroy/${id}`).then((res) => res).catch((error) => error);
}