import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Modal,
  FormHelperText,
} from '@mui/material';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import './ExpirationUpdate.scss';
import SwalUI from '../../../components/ui/swal-ui/swal-ui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Switch from '@mui/material/Switch';
import thLocale from 'dayjs/locale/th';
import dayjs from 'dayjs';
import InvoiceModal from './InvoiceModal';
import Swal from 'sweetalert2';
import ReceiptModal from './ReceiptModal';
import rows from '../data/TableData';
import { display } from '@mui/system';
import axios from 'axios';
import { updateExpiration } from '../../../services/expiration.service';
import { SaveAsPDFHandler } from './PDFModal';
// import dayjs from "dayjs";

// import CancelIcon from "@mui/icons-material/Cancel";

const date = new Date();
const today = date.toLocaleDateString('en-GB', {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
});

function ExpirationUpdate({
  onClose,
  totalSelectedRent,
  totalSelectedDeposit,
  selectedRows,
  blockNumbers,
  idShop,
  rents,
  overdus,
  blockAndRent,
  nameRent,
  nameShop,
  setRefreshData,
}) {
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [age, setAge] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const formInputRef = useRef(null);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [openshop, setOpenShop] = useState(false);
  // สร้าง state สำหรับเก็บค่าค่าเช่า
  const [rentalCosts, setRentalCosts] = useState(rents);
  // สร้าง state สำหรับเก็บยอดคงค้างจากreceipt
  const [overdusCosts, setOverdusCosts] = useState(overdus);
  const [fine, setFine] = useState(0);
  const [epxDate, setEpxDate] = useState(0);
  const [totalDay, setTotalDay] = useState(30);
  const [waterBill, setWaterBill] = useState(0);
  const [electricityBill, setElectricityBill] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [note, setNote] = useState('');
  const [totalFine, settTotalFine] = useState(0);
  const [isDate2Valid, setIsDate2Valid] = useState(true);
  const [isDate3Valid, setIsDate3Valid] = useState(true);
  const [isDate4Valid, setIsDate4Valid] = useState(true);
  const [isError, setIsError] = useState({
    status: false,
    startDate: false,
    endDate: false,
    imgslip: false,
  });
  const [overdue, setOverdue] = useState(0);

  console.log(blockAndRent);

  const handleCancel = () => {
    handleCloseShop();
  };
  const [openReceipt, setReceipt] = useState(false);

  const handleReceipt = () => {
    const total = calculateTotalToPay();

    const isDate2Empty = !selectedDate2;
    const isDate3Empty = !selectedDate3;
    const isDate4Empty = !age;

    setIsDate2Valid(!isDate2Empty);
    setIsDate3Valid(!isDate3Empty);
    setIsDate4Valid(!isDate4Empty);

    if (isDate2Empty || isDate3Empty) {
      Swal.fire({
        icon: 'warning',
        title: 'กรุณากรอกวันที่',
      });
    } else if (!selectedImage || !total || parseFloat(total) === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'กรุณาอัพโหลดสลิปก่อน',
      });
    } else {
      setReceipt(true);
    }
  };

  const handleOpenShop = () => {
    const total = calculateTotalToPay();
    const isDate2Empty = !selectedDate2;
    const isDate3Empty = !selectedDate3;

    setIsDate2Valid(!isDate2Empty);
    setIsDate3Valid(!isDate3Empty);

    if (!selectedDate2 || !selectedDate3 || !total || parseFloat(total) === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'กรุณากรอกข้อมูลให้ครบ',
      });
    } else {
      setOpenShop(true);
    }
  };

  const handleCloseReceipt = () => {
    setReceipt(false);
  };
  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    formInputRef.current.click();
  };

  const handleImageClick = () => {
    setSelectedImage('/images/background_img.jpg'); // เปลี่ยนเป็นที่ต้องการให้แสดง
  };

  // สร้างฟังก์ชันเพื่อคำนวณค่าเช่าเฉลี่ยต่อวัน
  const calculateAverageRent = () => {
    const averageRent = rentalCosts / 30;
    return averageRent.toLocaleString('en-US', { minimumFractionDigits: 2 });
  };

  const calculateTotalToPay = () => {
    const totalfine = epxDate * fine;

    if (totalFine !== totalfine) {
      // เพิ่มเงื่อนไขการเรียก setState เพื่อหยุดการเรียกใช้ซ้ำ
      settTotalFine(totalfine);
      // console.log(totalfine);
      console.log(overdue);
    }
    // console.log(totalfine );
    // console.log(rentalCosts);
    // console.log(overdusCosts);
    // ยอดรวมที่ต้องเก็บ
    const totalToPay =
      [...rentalCosts, ...overdusCosts].reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0) + totalfine;

    return totalToPay.toLocaleString('en-US', { minimumFractionDigits: 2 });
  };

  const overdueBalance = () => {
    console.log(overdusCosts);
    return overdusCosts.reduce((a, b) => a + b, 0);
  };

  // const calculateDateDifference = () => {
  //   if (selectedDate2 && selectedDate3) {
  //     const differenceInTime = selectedDate3 - selectedDate2;
  //     const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  //     return differenceInDays + 1;
  //   }
  //   return null;
  // };

  const handleRentalExtraDays = (event) => {
    setEpxDate(parseFloat(event.target.value.replace(/,/g, '')) || 0);
    setTotalDay(parseFloat(event.target.value.replace(/,/g, '')) || 0);
  };

  // ฟังก์ชันเพื่ออัพเดตค่าเช่าเฉพาะบล็อก
  const handleRentalCostChange = (index, value) => {
    const newRentalCosts = [...rentalCosts];
    newRentalCosts[index] = parseFloat(value.replace(/,/g, '')) || 0;
    setRentalCosts(newRentalCosts);
  };

  const handleUpdate = () => {
    // Open the modal
    handleReceipt();

    setTimeout(() => {
      // Generate the PDF
      SaveAsPDFHandler();
      console.log('PDF generated');
      console.log('Data saved');

      let isValid = true;
      let error = {
        ...isError,
        startDate: selectedDate2 === null,
        endDate: selectedDate3 === null,
        imgslip: selectedImage === null,
      };
      setIsError(() => error);

      const hasError = Object.values(error).find((err) => err === true);
      if (hasError) isValid = false;

      if (isValid) {
        const formData = new FormData();
        formData.append('idShop', JSON.stringify(idShop)); // ข้อมูลบล็อค id, เลขบล็อค, ชื่อผู้เช่า, ชื่อร้าน, ราคาบล็อค
        // formData.append("dataBlock", blockNumbers); // เลขบล็อค เช่น G08
        // formData.append("rentalCosts", rentalCosts); // ราคาบล็อค

        formData.append('date_start', selectedDate2); //วันที่เริ่มเช่า
        formData.append('date_end', selectedDate3); //วันสิ้นสุดการเช่า

        formData.append('total_day', totalDay); //จำนวนวันที่เช่า
        formData.append('dateExpire', epxDate); // วันที่เกินการเช่า

        formData.append('fine', fine); //ค่าปรับ
        formData.append('total_pay', calculateTotalToPay()); // ยอดรวมจ่ายทั้งหมด
        formData.append('overdue', overdue); // ยอดคงค้างชำระ
        // formData.append('status_pay', age); // สถานะการจ่าย
        formData.append('note', note); //หมายเหตุ
        formData.append('imageBill', formInputRef.current.files[0]); //สลิปการโอนชำระ

        /* Display the key/value pairs*/
        for (var pair of formData.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }
        // return false;

        updateExpiration(formData).then((res) => {
          // console.log(res);
          if (res.status === 201) {
            SwalUI({
              status: res.data.status,
              // description: res.data.description
            });
            setRefreshData((prev) => prev + 1);
            onClose(false);
          }

          // updateExpiration(formData).then((res) => {
          //   // console.log(res);
          //   if (res.status === 201) {
          //     SwalUI({
          //       status: res.data.status,
          //       // description: res.data.description
          //     });
          //     onClose(false);
          //   }
          // });
        });
      }
    }, 1500); // หน่วงเวลา delay
  };

  return (
    <section id="products-page">
      <div className="header">
        <div className="title-header">อัปเดต ข้อมูลการจ่ายค่าเช่า</div>

        <div className="box-buttonE">
          <Button
            onClick={handleOpenShop}
            variant="contained"
            sx={{
              background: ' #6C5DD3',
              width: '150px',
              textTransform: 'capitalize',
              fontSize: '16px',
            }}
            className="button-h"
          >
            ใบแจ้งหนี้
          </Button>
          <Button
            onClick={handleReceipt}
            variant="contained"
            sx={{
              background: ' #6C5DD3',
              width: '150px',
              textTransform: 'capitalize',
              fontSize: '16px',
            }}
            className="button-h"
          >
            ใบเสร็จรับเงิน
          </Button>
        </div>
      </div>

      <div className="box-store-expiration">
        <div className="store1">
          <p className="p1">รายละเอียดค่าเช่า</p>
          {/* กรอกข้อมูล */}
          <div className="from-box1">
            {blockNumbers.map((value, index) => (
              <React.Fragment key={index}>
                <div className="rental-box" style={{ width: '100%' }}>
                  <p style={{ fontWeight: '400', fontSize: '1rem' }}>
                    บล็อกที่เช่า {value}
                  </p>
                  <TextField
                    id="rentalCost"
                    label="ค่าเช่า"
                    multiline
                    maxRows={4}
                    variant="filled"
                    value={(rentalCosts[index] || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    onChange={(event) =>
                      handleRentalCostChange(index, event.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        background: 'white',
                        border: '1px solid #808080',
                        borderRadius: '5px',
                      },
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* store2 */}
        <div className="store2">
          <p className="p1">รอบการจ่ายประจำเดือน</p>
          {/* กรอกข้อมูล */}
          <div className="from-box2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="ตั้งแต่วันที่"
                inputFormat="DD/MM/YYYY"
                value={selectedDate2}
                onChange={(date) => setSelectedDate2(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        borderColor: isDate2Valid ? 'default' : 'red',
                      },
                      '& .MuiFormLabel-root': {
                        color: isDate2Valid ? 'default' : 'red',
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    error={isError.startDate}
                    required
                  />
                )}
              />
              <DatePicker
                label="ถึงวันที่"
                inputFormat="DD/MM/YYYY"
                value={selectedDate3}
                onChange={(date) => setSelectedDate3(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        borderColor: isDate3Valid ? 'default' : 'red',
                      },
                      '& .MuiFormLabel-root': {
                        color: isDate3Valid ? 'default' : 'red',
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    error={isError.endDate}
                    required
                  />
                )}
              />
            </LocalizationProvider>

            <TextField
              id="filled-multiline-flexible"
              label="รวมเป็นจำนวนวัน"
              multiline
              maxRows={4}
              variant="filled"
              defaultValue={0}
              value={totalDay}
              onChange={(event) =>
                setTotalDay(parseFloat(event.target.value) || 0)
              }
              InputProps={{
                disableUnderline: true,
                style: {
                  background: 'white',
                  border: '1px solid #808080', // เปลี่ยนสีขอบตามเงื่อนไข
                  borderRadius: '5px',
                },
              }}
            />
            <TextField
              id="filled-multiline-flexible"
              label="จำนวนวันที่เกินมา"
              multiline
              maxRows={4}
              value={epxDate}
              onChange={(event) =>
                setEpxDate(parseFloat(event.target.value) || 0)
              }
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  background: 'white',
                  border: '1px solid #808080', // เปลี่ยนสีขอบตามเงื่อนไข
                  borderRadius: '5px',
                },
              }}
            />
          </div>
        </div>

        {/* store3 */}
        <div className="store3">
          <p className="p1">รวมยอดที่ต้องจ่าย</p>
          {/* กรอกข้อมูล */}
          <div className="from-box3">
            <div className="Switch" style={{ width: '100%' }}>
              <TextField
                id="fine"
                label="ค่าปรับ"
                multiline
                maxRows={4}
                value={fine.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(event) =>
                  setFine(parseFloat(event.target.value.replace(/,/g, '')) || 0)
                }
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    background: 'white',
                    border: '1px solid #808080',
                    borderRadius: '5px',
                  },
                }}
              />

              {/* <Switch {...label} defaultChecked /> */}
            </div>
            <TextField
              id="filled-multiline-flexible"
              label="รวมยอดที่จะต้องเก็บ"
              multiline
              maxRows={4}
              variant="filled"
              value={calculateTotalToPay()}
              InputProps={{
                disableUnderline: true,
                style: {
                  background: '#f3f4f6',
                  border: '1px solid #808080',
                  borderRadius: '5px',
                },
              }}
              disabled
            />

            <TextField
              id="filled-multiline-flexible"
              label="ยอดคงค้าง"
              multiline
              maxRows={4}
              variant="filled"
              // defaultValue={0}
              // value={overdusCosts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              value={overdueBalance()}
              onChange={(event) =>
                setOverdue(
                  parseFloat(event.target.value.replace(/,/g, '')) || 0
                )
              }
              InputProps={{
                disableUnderline: true,
                style: {
                  background: 'white',
                  border: '1px solid #808080',
                  borderRadius: '5px',
                },
              }}
              disabled
            />
          </div>
        </div>

        {/* store4 */}
        <div className="store4">
          {/* <p className="p1">สถานะการจ่าย</p> */}
          {/* กรอกข้อมูล */}
          <div className="from-box4">
            <div className="flex-box">
              {/* <FormControl
                fullWidth
                style={{ width: "100%" }}
                error={!isDate4Valid}
              >
                <InputLabel id="demo-select-small-label">
                  เลือกสถานะการจ่าย
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={age}
                  label="เลือกสถานะการจ่าย"
                  onChange={handleChange}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value={1}>จ่ายแล้ว</MenuItem>
                  <MenuItem value={0}>ยังไม่จ่าย</MenuItem>
                </Select>
              </FormControl> */}

              <textarea
                aria-label="empty textarea"
                placeholder="หมายเหตุ"
                value={note !== null ? note : ''}
                onChange={(event) => setNote(event.target.value)}
                // minRows={4}
                style={{
                  border: '1px solid #808080',
                  borderRadius: '5px',
                  padding: '8px',
                  width: '100%',
                  resize: 'vertical',
                  height: '100px',
                }}
              />
            </div>
            <div className="flex-box">
              {/* <div className="content-lefts"> */}
              <div
                className={`content-lefts ${isError.imgslip ? 'error' : ''}`}
              >
                <div className="figure-container" onClick={handleButtonClick}>
                  <figure style={{ cursor: 'pointer' }}>
                    {selectedImage && (
                      <img
                        className="content-images"
                        src={selectedImage}
                        alt="Selected"
                      />
                    )}

                    {/* แสดงรูปภาพที่เลือก */}
                    <div className="input-file">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={previewImageHandler}
                        ref={formInputRef}
                        style={{ display: 'none' }}
                        error={isError.imgslip}
                      />
                    </div>
                    {selectedImage ? (
                      ''
                    ) : (
                      <div className="figure-text">
                        <InsertPhotoOutlinedIcon
                          fontSize="large"
                          style={{ color: 'grey' }}
                        />
                        <p
                          style={{
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#707070',
                            textAlign: 'center',
                          }}
                        >
                          รูปถ่าย หรือ รูปสลิปหลักฐานการจ่าย
                        </p>
                      </div>
                    )}
                  </figure>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={handleButtonClick}
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: '16px',
                    background: '#6C5DD3',
                    color: 'white',
                    height: '35px',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                  }}
                  className="button-h"
                >
                  เลือกรูปภาพ
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="status-buttonE">
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            width: '150px',
          }}
          component="a"
          onClick={onClose}
          // href="/expiration"
          className="button-h"
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={handleUpdate}
          style={{
            fontSize: '16px',
            background: '#6C5DD3',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            width: '150px',
          }}
          component="a"
          className="button-h"
        >
          บันทึกข้อมูล
        </Button>
      </div>

      <Modal open={openshop} onClose={handleCloseShop}>
        <InvoiceModal
          blockNumbers={blockNumbers}
          rentalCosts={rentalCosts}
          blockAndRent={blockAndRent}
          nameRent={nameRent}
          nameShop={nameShop}
          invoiceValue={{
            waterBill,
            electricityBill,
            deposit,
            note,
            totalToPay: calculateTotalToPay(),
            totalFine: totalFine,
            selectedDate2,
            selectedDate3,
            overdue: overdueBalance(),
          }}
        />
      </Modal>

      <Modal open={openReceipt} onClose={handleCloseReceipt}>
        <ReceiptModal
          blockNumbers={blockNumbers}
          rentalCosts={rentalCosts}
          blockAndRent={blockAndRent}
          nameRent={nameRent}
          nameShop={nameShop}
          receiptValue={{
            waterBill,
            electricityBill,
            deposit,
            note,
            totalToPay: calculateTotalToPay(),
            totalFine: totalFine,
            selectedDate2,
            selectedDate3,
            overdue: overdueBalance(),
          }}
        />
      </Modal>
    </section>
  );
}

export default ExpirationUpdate;
