import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import Form from "../components/Form";
import rows from "../data/TableData";
import {
  getRentData,
  getZoneData,
  getBoxsZoneData,
  getShop,
  getTenant,
  getBoxsData,
} from "../../../services/rent.service";
import { svDataWaterBill } from "../../../services/expenses/waterbill.service";

function Table({ rentDetail, exportedProductDetails, refreshData, setRefreshData }) {
  const { id } = useParams();
  const { t } = useTranslation(["dashboard-page"]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [year, setYear] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [rentData, setRentData] = useState([]);
  const [boxsZoneData, setBoxsZoneData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [test2, setTest2] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  console.log("rows", rows);
  console.log("test2", rentDetail);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const rentResponse = await getRentData();
        // const rentDataRes = rentResponse.data.rent;
        // setRentData(rentDataRes);

        // const boxsZoneResponse = await getBoxsZoneData();
        // const boxsZoneDataRes = boxsZoneResponse.data.boxs;
        // setBoxsZoneData(boxsZoneDataRes);

        // const tenantResponse = await getTenant();
        // const tenantDataRes = tenantResponse.data.tenants;
        // setTenantData(tenantDataRes);

        // const combinedRows = rentDataRes.map((rent, index) => {
        //   const matchedBox = boxsZoneDataRes.find(
        //     (box) => box.box_size === rent.BlockSize
        //   );
        //   const matchedTenant = tenantDataRes.find(
        //     (tenant) => tenant.id === rent.id
        //   );
        //   return {
        //     ...rent,
        //     ...matchedBox,
        //     ...matchedTenant,
        //     id: rent.id || `rent-${index}`,
        //   };
        // });

        setRows(rentDetail);

        // const current = combinedRows.find((row) => row.id === parseInt(id));
        // console.log("current", current);
        // setCurrentRow(current);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);


  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const showTableHandler = () => {
    setShowTable(true);
    setShowForm(false);
  };

  const showFormHandler = () => {
    setShowTable(false);
    setShowForm(true);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "PayDate",
      headerName: "วันที่",
      width: 174,
      sortable: true,
      renderCell: (params) => {
        const Datevalue = params.value;

        if (!Datevalue || !Datevalue.trim()) {
          return <p>--/--/--</p>;
        }
        return Datevalue;
      },
    },
    {
      field: "tentfee",
      headerName: "ค่ามัดจำเต็นท์",
      width: 174,
      headerAlign: "center",
      align: "center",
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "deposit",
      headerName: "ค่ามัดจำ",
      width: 174,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "rent",
      headerName: "ค่าเช่า",
      width: 174,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    // {
    //   field: "ElectricityBill",
    //   headerName: "ค่าไฟ",
    //   width: 174,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   valueFormatter: (params) => {
    //     if (params.value == null) {
    //       return "";
    //     }
    //     return new Intl.NumberFormat("th-TH", {
    //       maximumFractionDigits: 0,
    //     }).format(params.value);
    //   },
    //   renderCell: (params) => {
    //     const formattedValue = new Intl.NumberFormat("th-TH", {
    //       maximumFractionDigits: 0,
    //     }).format(params.value);

    //     if (!params.value) {
    //       return <p>----฿</p>;
    //     }
    //     return `${formattedValue}`;
    //   },
    // },
    {
      field: "fines",
      headerName: "ค่าปรับ",
      width: 174,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "total_days",
      width: 174,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            จำนวนวันที่เช่า
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ของรอบบิล
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const numberDay = params.value;

        if (!numberDay) {
          return <p>--วัน</p>;
        }
        return numberDay;
      },
    },
    {
      field: "PayRent",
      width: 180,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            วันที่ต้องจ่ายค่า
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            เช่าในแต่ละเดือน
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;

        if (!PayRentvalue || !PayRentvalue.trim()) {
          return <p>วันที่ 01 ของทุกเดือน</p>;
        }
        return PayRentvalue;
      },
    },
    {
      field: "slip",
      headerName: "หลักฐานการจ่าย",
      width: 174,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleOpen(uploadPath + params.row.slip)}
        >
          <img
            src={
              params.value
                ? `/images/mingcute_paper-line.png`
                : "/images/no-image.png"
            }
            alt={""}
            style={{
              maxHeight: "200px",
              maxWidth: "100%",
            }}
          />
          <p style={{ color: "#3F8CFF" }}>view</p>
        </div>
      ),
    },
  ];

  const exportToExcel = () => {
    const headers = {
      วันที่: "Date",
      ค่ามัดจำเต็นท์: "TentDeposit",
      ค่ามัดจำ: "Deposit",
      ค่าเช่า: "Rent",
      ค่าน้ำ: "WaterBill",
      ค่าไฟ: "ElectricityBill",
      ค่าปรับ: "Fine",
      "จำนวนวันที่เช่า ของรอบบิล": "NumberOfDays",
      "วันที่ต้องจ่ายค่า เช่าในแต่ละเดือน": "PayRent",
    };

    const mappedRows = rows.map((row) => {
      const mappedRow = {};
      for (const key in headers) {
        mappedRow[key] = row[headers[key]];
      }
      return mappedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["รายละเอียด ประวัติเช่า/ชำระเงิน ร้าน Shop A"]],
      {
        origin: "A1",
      }
    );
    worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }];

    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(headers)], {
      origin: "A2",
    });

    XLSX.utils.sheet_add_json(worksheet, mappedRows, {
      origin: "A3",
      skipHeader: true,
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredData");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(data, "HistoryData.xlsx");
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        className="box-button"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
          gap: "2rem",
          marginBottom: "1rem",
        }}
      >
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            gap: "1rem",
          }}
        >
          <Button
            onClick={showTableHandler}
            variant="contained"
            sx={{
              background: showTable ? "#6C5DD3" : "#707070",
              width: "174px",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
            className="button-h"
          >
            ประวัติการเช่า
          </Button>

          {/* <Button
            onClick={showFormHandler}
            variant="contained"
            sx={{
              background: showForm ? "#6C5DD3" : "#707070",
              width: "174px",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
            className="button-h"
          >
            ข้อมูลร้าน
          </Button> */}
        </div>
        {showTable && (
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              gap: "1rem",
            }}
          >
            <Button
              onClick={exportToExcel}
              variant="contained"
              size="small"
              style={{
                fontSize: "14px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
            >
              EXPORT TO EXCEL
            </Button>
          </div>
        )}
      </div>

      {showTable && (
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={70}
          rowsPerPageOptions={[5]}
          className="custom-data-grid"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
      {/* {showForm && (
        <div className="container-form">
          <Form />
        </div>
      )} */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Table;
